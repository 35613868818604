import {AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { SideBarManager } from './utils/sidebar-manager';
import {Subject} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  @ViewChild("presenceChecker") presenceChecker!: TemplateRef<any>;
  @ViewChild("leftSystem") leftSystem!: TemplateRef<any>;
  title = 'records';
  hasOpened!: boolean;
  navStyle = {};
  counter: number = 20;
  currentCounter: number = 20;
  isHere: boolean = true;
  tokenLifeTime = 30;
  dialogIsOpened = false;

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  awayTime: string = "";

  dialogRef?: MatDialogRef<any>;
  matDialogRef?: MatDialogRef<any>;

  constructor(private sideBarManager: SideBarManager, private keycloakService: KeycloakService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: e => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloakService.updateToken(this.tokenLifeTime * 60);

        }
      }
    });

    this.userInactive.subscribe(() => {
      this.dialogIsOpened = true;

      this.isHere = false;
      this.currentCounter = this.counter;
      this.dialogRef = this.dialog.open(this.presenceChecker, {
        minHeight: 400,
        minWidth: 400
      });
      this.dialogRef.afterClosed().subscribe({
        next: res => {
          this.dialogIsOpened = false;
        }
      });
      let it = setInterval(
        () => {
          if (this.isHere) {
            this.keycloakService.updateToken(this.tokenLifeTime * 60);
            this.dialogRef?.close();
            clearInterval(it);
          } else if (this.currentCounter <= 0) {
            this.dialogRef?.close();
            localStorage.setItem("awayFromSystem", "true");
            localStorage.setItem("awayTime", new Date().toISOString());
            this.keycloakService.logout();
            clearInterval(it);
          }
          this.currentCounter--;
        },
        1000
      );
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (!this.dialogIsOpened) {
        this.userInactive.next(undefined);
      }
    }, this.tokenLifeTime * 60 * 1000);
    // }, this.tokenLifeTime * 1000);
  }


  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem("awayFromSystem")) {
      setTimeout(() => {
        this.awayTime = localStorage.getItem("awayTime") ?? "";
        this.matDialogRef = this.dialog.open(this.leftSystem, {
          minHeight: 400,
          minWidth: 400
        });
        localStorage.removeItem("awayFromSystem");
      }, 5000);
    }
  }

  isOpened() {
    this.hasOpened = true;
    this.navStyle = {width: '86%'};
    this.sideBarManager.setIsOpen(this.hasOpened);
  }

  isClosed() {
    this.hasOpened = false;
    this.navStyle = {width: '100%'};
    this.sideBarManager.setIsOpen(this.hasOpened);
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
