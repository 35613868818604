import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdaptersAndMigrationsService } from './api/adaptersAndMigrations.service';
import { AntenatalBabyMumService } from './api/antenatalBabyMum.service';
import { AntenatalEnrollmentService } from './api/antenatalEnrollment.service';
import { AntenatalQuestionAndAnswerService } from './api/antenatalQuestionAndAnswer.service';
import { AppointmentService } from './api/appointment.service';
import { BeneficiaryService } from './api/beneficiary.service';
import { CardCollectionService } from './api/cardCollection.service';
import { CheckInService } from './api/checkIn.service';
import { ConfigService } from './api/config.service';
import { GroupService } from './api/group.service';
import { ImmunizationService } from './api/immunization.service';
import { ImmunizationAppointmentService } from './api/immunizationAppointment.service';
import { ImmunizationPatientService } from './api/immunizationPatient.service';
import { KioskService } from './api/kiosk.service';
import { PatientService } from './api/patient.service';
import { PreviousPregnancyService } from './api/previousPregnancy.service';
import { ReligionsService } from './api/religions.service';
import { StatesAndLgasService } from './api/statesAndLgas.service';
import { StatisticsService } from './api/statistics.service';
import { SubscriptionsService } from './api/subscriptions.service';
import { TribeService } from './api/tribe.service';
import { VisitorRequestService } from './api/visitorRequest.service';
import { VitalsService } from './api/vitals.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
