/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialVitalsFilterDateRange } from './partialVitalsFilterDateRange';
import { IDetails } from './iDetails';
import { PartialVitalsFilterBloodPressure } from './partialVitalsFilterBloodPressure';


/**
 * Make all properties in T optional
 */
export interface PartialVitalsFilter { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientHospitalNumber?: string;
    patientEmail?: string;
    patientPhone?: string;
    patientSex?: string;
    patientDateOfBirth?: string;
    patientId?: any | null;
    appointmentId?: any | null;
    admissionId?: string;
    temperature?: IDetails;
    weight?: IDetails;
    urinalysis?: Array<IDetails>;
    fhr?: IDetails;
    bloodPressure?: PartialVitalsFilterBloodPressure;
    height?: IDetails;
    bmi?: IDetails;
    headCircumference?: IDetails;
    pulseRate?: IDetails;
    respiratoryRate?: IDetails;
    sp02?: IDetails;
    timeTaken?: string;
    recordedByUsername?: string;
    recordedByFirstName?: string;
    recordedByLastName?: string;
    recordedByDesignation?: string;
    recordedById?: string;
    created_at?: string;
    updated_at?: string;
    error?: string;
    mode?: PartialVitalsFilter.ModeEnum;
    machineLocation?: string;
    machineId?: string;
    vitalsKey?: any | null;
    dateRange?: PartialVitalsFilterDateRange;
}
export namespace PartialVitalsFilter {
    export type ModeEnum = 'machine' | 'self' | 'hospital_staff';
    export const ModeEnum = {
        Machine: 'machine' as ModeEnum,
        Self: 'self' as ModeEnum,
        HospitalStaff: 'hospital_staff' as ModeEnum
    };
}


