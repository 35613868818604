/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WalletFilter { 
    id?: number;
    hospital_number?: string;
    balance?: number;
    status?: WalletFilter.StatusEnum;
    walletType?: WalletFilter.WalletTypeEnum;
}
export namespace WalletFilter {
    export type StatusEnum = 'OPEN' | 'BLOCKED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        Blocked: 'BLOCKED' as StatusEnum
    };
    export type WalletTypeEnum = 'REGULAR' | 'ADMISSION';
    export const WalletTypeEnum = {
        Regular: 'REGULAR' as WalletTypeEnum,
        Admission: 'ADMISSION' as WalletTypeEnum
    };
}


