/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialIPatient } from './partialIPatient';


export interface KioskRegistrationInfo { 
    data: PartialIPatient;
    auto_generate_hospital_number?: KioskRegistrationInfo.AutoGenerateHospitalNumberEnum;
    group_id: string;
}
export namespace KioskRegistrationInfo {
    export type AutoGenerateHospitalNumberEnum = 'true' | 'false';
    export const AutoGenerateHospitalNumberEnum = {
        True: 'true' as AutoGenerateHospitalNumberEnum,
        False: 'false' as AutoGenerateHospitalNumberEnum
    };
}


