/**
 * pharmacy-service-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8.2
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IAdjuvant { 
    _id?: string;
    name?: string;
    code?: string;
    category?: IAdjuvant.CategoryEnum;
    created_at?: string;
    updated_at?: string;
}
export namespace IAdjuvant {
    export type CategoryEnum = 'Adjuvant';
    export const CategoryEnum = {
        Adjuvant: 'Adjuvant' as CategoryEnum
    };
}


