/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IAnswer } from './iAnswer';
import { IAudio } from './iAudio';
import { ITranslation } from './iTranslation';
import { IQuestionAction } from './iQuestionAction';
import { IImage } from './iImage';


export interface IQuestion { 
    _id?: string;
    key?: string;
    text?: string;
    dependsOn?: string;
    surveyType?: string | null;
    isManual?: IQuestion.IsManualEnum;
    translations?: ITranslation;
    imageUrl?: string;
    image?: IImage;
    audio?: IAudio;
    answers?: Array<IAnswer>;
    action: IQuestionAction;
    survey_id?: string | null;
    multiple?: boolean;
    dateCreated?: string;
    dateUpdated?: string;
}
export namespace IQuestion {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
}


