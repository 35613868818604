/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.5.0
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IMeta } from './iMeta';


export interface RadiologyRequest { 
    id?: number;
    patient_name?: string;
    patient_sex?: string;
    patient_dob?: string;
    patient_hmo_code?: string;
    hmo_code?: string;
    appointment_id?: string;
    appointment_code?: string;
    hospital_number: string;
    hospital_uuid?: string;
    departmentRoute?: string;
    description?: string;
    service_id?: number;
    service_ids?: Array<number>;
    service_name?: string;
    test_package_name: string;
    test_package_id: number;
    status?: RadiologyRequest.StatusEnum;
    paymentStatus?: boolean;
    raised_by_id?: string;
    raised_by_name: string;
    meta?: IMeta;
    slug?: string;
    created?: string;
    updated?: string;
    deletedAt?: string;
}
export namespace RadiologyRequest {
    export type StatusEnum = 'pending' | 'in-progress' | 'completed' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


