/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ICause } from './iCause';
import { IQuestion } from './iQuestion';


export interface ISurvey { 
    _id?: string;
    key?: string;
    surveyType?: string | null;
    isManual?: ISurvey.IsManualEnum;
    firstQuestion?: IQuestion;
    causes?: Array<ICause>;
    dateCreated?: string;
    dateUpdated?: string;
}
export namespace ISurvey {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
}


