import { APP_INITIALIZER, NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { RoundBlockDirective } from "./directives/round-block.directive";
import { RecordsMainComponent } from "./records-main/records-main.component";
import { STORE_MODULES } from "./shared-file";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
import { NavComponent } from "./components/nav/nav.component";
import { AuthService } from "./services/auth.service";
import { initializer, environment } from "./AppInit";
import { KeycloakService, KeycloakAngularModule } from "keycloak-angular";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClientModule } from "@angular/common/http";

import { BASE_PATH as HMO_BASE_PATH } from "hmo-sdk";
import { BASE_PATH as RECORDS_BASE_PATH } from "records-api-sdk";

import { BASE_PATH as MEDICAL_HISTORY_BASE_PATH } from "medical-history-sdk";
import { BASE_PATH as SERVICE_SERVICE_BASE_PATH } from "service-service-sdk";
import { BASE_PATH as MY_HOME_CLINIC_BASE_PATH } from "my-home-clinic-sdk";
import { BASE_PATH as PROXY_BASE_PATH } from "proxy-sdk";
import { BASE_PATH as CLINICALS_BASE_PATH } from "clinicals-api-sdk";
import { BASE_PATH as FINANCE_BASE_PATH } from "finance-sdk";
import { BASE_PATH as LAB_BASE_PATH } from "lab-sdk";
import { BASE_PATH as PHARMACY_BASE_PATH } from "pharmacy-sdk";
import { SharedModule } from "./shared/shared.module";
import { SocketService } from "./services/socket.service";
import { ConfigService } from "./config.service";
@NgModule({
  declarations: [
    AppComponent,
    RoundBlockDirective,
    RecordsMainComponent,
    SideBarComponent,
    NavComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    ...STORE_MODULES,
    HttpClientModule,
    KeycloakAngularModule,
    SharedModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    KeycloakService,
    SocketService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, ConfigService],
    },
    AuthService,
    {
      provide: RECORDS_BASE_PATH,
      useValue: ConfigService.config.records_url,
    },
    {
      provide: HMO_BASE_PATH,
      useValue: ConfigService.config.hmo_url,
    },
    {
      provide: MEDICAL_HISTORY_BASE_PATH,
      useValue: ConfigService.config.medical_url,
    },
    {
      provide: SERVICE_SERVICE_BASE_PATH,
      useValue: ConfigService.config.service_url,
    },
    {
      provide: MY_HOME_CLINIC_BASE_PATH,
      useValue: ConfigService.config.care_url,
    },
    {
      provide: CLINICALS_BASE_PATH,
      useValue: ConfigService.config.clinicals_engine_url,
    },
    {
      provide: FINANCE_BASE_PATH,
      useValue: ConfigService.config.finance_url,
    },
    {
      provide: PHARMACY_BASE_PATH,
      useValue: ConfigService.config.pharmacy_url,
    },
    {
      provide: LAB_BASE_PATH,
      useValue: ConfigService.config.lab_url,
    },
    {
      provide: PROXY_BASE_PATH,
      useValue: ConfigService.config.care_url,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
