// import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogConfig, MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatRadioModule } from "@angular/material/radio";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { CommonModule } from "@angular/common";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatSidenavModule } from "@angular/material/sidenav";
import { HttpClientModule } from "@angular/common/http";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PipesModule } from "./pipes/pipes.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { WebcamModule } from "ngx-webcam";

// import { ComponentsModule } from "../components/components.module";

export const STORE_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  FormsModule,
  MatTableModule,
  CommonModule,
  // FlexLayoutModule,
  // ComponentsModule,
  MatGridListModule,
  MatIconModule,
  MatButtonModule,
  MatProgressBarModule,
  MatTabsModule,
  MatMenuModule,
  MatDividerModule,
  MatTooltipModule,
  MatToolbarModule,
  MatListModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatStepperModule,
  MatCardModule,
  MatDatepickerModule,
  MatChipsModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatTabsModule,
  MatInputModule,
  FormsModule,
  ReactiveFormsModule,
  MatButtonModule,
  FlexLayoutModule,
  MatIconModule,
  MatDialogModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatMomentDateModule,
  MatSidenavModule,
  HttpClientModule,
  MatProgressSpinnerModule,
  PipesModule,
  MatCheckboxModule,
  NgxQRCodeModule,
  WebcamModule,
];
