import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RecordsMainComponent } from './records-main/records-main.component';
import { RegisterPatientComponent } from './records-main/register-patient/register-patient.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./records-main/records-main.module').then(
        (m) => m.RecordsMainModule
      ),
  },
  {
    path: 'my-home-clinic',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./my-home-clinic/my-home-clinic.module').then(
        (m) => m.MyHomeClinicModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
