/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GatewayChargeParamsDto { 
    email: string;
    amount: number;
    hospital_number?: string;
    chargeType?: GatewayChargeParamsDto.ChargeTypeEnum;
    phone?: string;
    bankCode?: string;
    bankAccountNumber?: string;
    ussdCode?: string;
    description?: string;
    branch?: string;
    redirect_url?: string;
    /**
     * This is required for Global Accelerex Payments
     */
    service_type?: object;
}
export namespace GatewayChargeParamsDto {
    export type ChargeTypeEnum = 'bank' | 'ussd' | 'card';
    export const ChargeTypeEnum = {
        Bank: 'bank' as ChargeTypeEnum,
        Ussd: 'ussd' as ChargeTypeEnum,
        Card: 'card' as ChargeTypeEnum
    };
}


