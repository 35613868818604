import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import { ConfigService } from '../../config.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  userGuideUrl = ConfigService.config.user_guide_url;


  constructor() { }

  ngOnInit(): void {
  }

}
