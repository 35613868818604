/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GatewayPaymentNotificationDto { 
    TransactionReference: string;
    Reference?: string;
    /**
     * >= 0.01
     */
    Amount: number;
    Type?: GatewayPaymentNotificationDto.TypeEnum;
    RetrievalReferenceNumber: string;
    MaskedPAN?: string;
    CardScheme?: string;
    CustomerName?: string;
    StatusCode: string;
    StatusDescription: string;
    AdditionalInformation?: Array<object>;
    /**
     * 3 Characters e.g. USD, NGN
     */
    Currency: string;
    MerchantId: string;
    Stan: string;
    CardExpiry?: string;
    CardHash?: string;
    /**
     * Payment date format is YYYY-MM-DD
     */
    PaymentDate: string;
}
export namespace GatewayPaymentNotificationDto {
    export type TypeEnum = 'Invoice' | 'Purchase';
    export const TypeEnum = {
        Invoice: 'Invoice' as TypeEnum,
        Purchase: 'Purchase' as TypeEnum
    };
}


