/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdatePaymentDto { 
    id: number;
    txType?: UpdatePaymentDto.TxTypeEnum;
    paymentMethod?: UpdatePaymentDto.PaymentMethodEnum;
    online_reference?: string;
    invoiceNumber?: number;
    amount?: number;
    quantity?: number;
    itemId?: string;
    itemName?: string;
    itemCode?: string;
    payerId?: string;
    payerName?: string;
    payerUsername?: string;
    payerPhone?: string;
    payerSex?: string;
    departmentRoute?: string;
    payerIdNumber?: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoApprovalCode?: string;
    hmoAmount?: number;
    hmoStatus?: UpdatePaymentDto.HmoStatusEnum;
    hmoCode?: string;
    payerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    payerPackage?: string;
    subaccount?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    raisedById?: string;
    raisedByName?: string;
    requestId?: string;
    requestCategoryKey?: string;
    requestCategory?: string;
    organizationId?: string;
    slug?: string;
    receivedAt?: string;
}
export namespace UpdatePaymentDto {
    export type TxTypeEnum = 'INCOME' | 'EXPENSE' | 'DISCOUNT' | 'REVERSAL';
    export const TxTypeEnum = {
        Income: 'INCOME' as TxTypeEnum,
        Expense: 'EXPENSE' as TxTypeEnum,
        Discount: 'DISCOUNT' as TxTypeEnum,
        Reversal: 'REVERSAL' as TxTypeEnum
    };
    export type PaymentMethodEnum = 'DEFAULT' | 'CASH' | 'POS' | 'CHEQUE' | 'TRANSFER' | 'ONLINE' | 'PAY_LATER' | 'EXEMPTED' | 'HMO' | 'WALLET';
    export const PaymentMethodEnum = {
        Default: 'DEFAULT' as PaymentMethodEnum,
        Cash: 'CASH' as PaymentMethodEnum,
        Pos: 'POS' as PaymentMethodEnum,
        Cheque: 'CHEQUE' as PaymentMethodEnum,
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        Online: 'ONLINE' as PaymentMethodEnum,
        PayLater: 'PAY_LATER' as PaymentMethodEnum,
        Exempted: 'EXEMPTED' as PaymentMethodEnum,
        Hmo: 'HMO' as PaymentMethodEnum,
        Wallet: 'WALLET' as PaymentMethodEnum
    };
    export type HmoStatusEnum = 'APPROVED' | 'CANCELLED' | 'DEFAULT' | 'IN_PROGRESS';
    export const HmoStatusEnum = {
        Approved: 'APPROVED' as HmoStatusEnum,
        Cancelled: 'CANCELLED' as HmoStatusEnum,
        Default: 'DEFAULT' as HmoStatusEnum,
        InProgress: 'IN_PROGRESS' as HmoStatusEnum
    };
}


