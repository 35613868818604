/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VisitorTimeInOrOutRequest { 
    type: VisitorTimeInOrOutRequest.TypeEnum;
    visitorCode: string;
    patientUid: string;
}
export namespace VisitorTimeInOrOutRequest {
    export type TypeEnum = 'timein' | 'timeout';
    export const TypeEnum = {
        Timein: 'timein' as TypeEnum,
        Timeout: 'timeout' as TypeEnum
    };
}


