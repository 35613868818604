/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Make all properties in T optional
 */
export interface PartialIGroup { 
    _id?: string;
    title?: string;
    numberOfPersons?: number;
    cost?: number;
    isDefault?: PartialIGroup.IsDefaultEnum;
    isDeleted?: PartialIGroup.IsDeletedEnum;
    created_at?: string;
    updated_at?: string;
    error?: string;
    isPublic?: boolean;
}
export namespace PartialIGroup {
    export type IsDefaultEnum = false | true;
    export const IsDefaultEnum = {
        False: false as IsDefaultEnum,
        True: true as IsDefaultEnum
    };
    export type IsDeletedEnum = false | true;
    export const IsDeletedEnum = {
        False: false as IsDeletedEnum,
        True: true as IsDeletedEnum
    };
}


