/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WalletTransactionFilter { 
    id?: number;
    createdByName?: string;
    createdById?: string;
    status?: WalletTransactionFilter.StatusEnum;
    transactionType?: WalletTransactionFilter.TransactionTypeEnum;
    paymentMethod?: WalletTransactionFilter.PaymentMethodEnum;
}
export namespace WalletTransactionFilter {
    export type StatusEnum = 'OPEN' | 'BLOCKED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        Blocked: 'BLOCKED' as StatusEnum
    };
    export type TransactionTypeEnum = 'CREDIT' | 'DEBIT';
    export const TransactionTypeEnum = {
        Credit: 'CREDIT' as TransactionTypeEnum,
        Debit: 'DEBIT' as TransactionTypeEnum
    };
    export type PaymentMethodEnum = 'DEFAULT' | 'CASH' | 'POS' | 'CHEQUE' | 'TRANSFER' | 'ONLINE' | 'PAY_LATER' | 'EXEMPTED' | 'HMO' | 'WALLET';
    export const PaymentMethodEnum = {
        Default: 'DEFAULT' as PaymentMethodEnum,
        Cash: 'CASH' as PaymentMethodEnum,
        Pos: 'POS' as PaymentMethodEnum,
        Cheque: 'CHEQUE' as PaymentMethodEnum,
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        Online: 'ONLINE' as PaymentMethodEnum,
        PayLater: 'PAY_LATER' as PaymentMethodEnum,
        Exempted: 'EXEMPTED' as PaymentMethodEnum,
        Hmo: 'HMO' as PaymentMethodEnum,
        Wallet: 'WALLET' as PaymentMethodEnum
    };
}


