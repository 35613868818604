/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IDifferentialInvestigations } from './iDifferentialInvestigations';
import { IDifferentialWeightedKeys } from './iDifferentialWeightedKeys';
import { IDifferentialPrescriptions } from './iDifferentialPrescriptions';
import { IDifferentialExaminations } from './iDifferentialExaminations';


export interface IDifferential { 
    _id?: string;
    key?: string;
    title?: string;
    differentialType?: string | null;
    isManual?: IDifferential.IsManualEnum;
    treatment?: string | null;
    answerKeys?: Array<string>;
    weightedKeys?: Array<IDifferentialWeightedKeys>;
    keyPoints?: number;
    investigations?: IDifferentialInvestigations;
    examinations?: Array<IDifferentialExaminations>;
    prescriptions?: Array<IDifferentialPrescriptions>;
    dateCreated?: string;
    dateUpdated?: string;
}
export namespace IDifferential {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
}


