<div class="side-bar-main" fxLayout="column wrap">
    <div fxLayout="column wrap" fxLayoutAlign="start" class="title">
        <div class="side-bar-title" fxLayout="column">
            <img alt="logo" style="width: 180px;" src="../../../assets/img/logo-landscape.png"/>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" class="container">
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/patients'>
                    <mat-icon class="side-icon">person_outline</mat-icon>
                    <span>Patients</span>
                </a>
            </div>
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/appointment-dashboard'>
                    <mat-icon  class="side-icon">today</mat-icon>
                    <span>Appointments</span>
                </a>
            </div>
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/immunization'>
                    <mat-icon class="side-icon">queue</mat-icon>
                    <span> Immunization</span>
                </a>
            </div>
            <!-- <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/procedures'>
                    <mat-icon>monitor_heart</mat-icon>
                    <span>Procedures</span>
                </a>
            </div> -->
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/my-home-clinic/connection-requests'>
                    <mat-icon  class="side-icon">mobile_friendly</mat-icon>
                    <span>My Home Clinic</span>
                </a>
            </div>
            <!-- <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/caregivers-history'>
                    <mat-icon class="side-icon">supervisor_account</mat-icon>
                    <span> Caregivers History</span>
                </a>
            </div> -->
            <div class="side-bar-item" routerLinkActive="activeLink" fxLayout="column">
                <a routerLink='/settings'>
                    <mat-icon class="side-icon">settings</mat-icon>
                    <span>Settings</span>
                </a>
            </div>

        </div>
    </div>

  <div class="mt-5">
    <a class="muted-text" [href]="userGuideUrl" target="_blank">User Guide</a>
  </div>
</div>
