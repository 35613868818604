import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private keycloakService: KeycloakService) {}

  getLoggedUser() {
    try {
      let userDetails =
        this.keycloakService.getKeycloakInstance().idTokenParsed;

      // console.log('user details', userDetails);
      // console.log('user roles', this.keycloakService.getUserRoles());
      return userDetails;
    } catch (e) {
      console.log('getLoggedUser exception', e);
      return undefined;
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  redirectToProfile() {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  logoutIfTokenExpired() {
    this.keycloakService.isLoggedIn().then((data) => {
      if (!data) {
        this.logout();
      }
    });
  }

  getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }
}
