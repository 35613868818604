import { Injectable } from '@angular/core';
import { IAppointment } from 'records-api-sdk';
import { io } from 'socket.io-client';
import { ConfigService } from '../config.service';

type AcceptedRequestTypes =
  | 'generated-bills'
  | 'cleared-bills'
  | 'deleted-bills'
  | 'reversed-bills';
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  newBillSound = new Audio('assets/sound/generated-bill.wav');
  clearedBillSound = new Audio('assets/sound/cleared-bill.wav');
  constructor() {
    const manager1 = io(ConfigService.config.socket_url + '/', {
      transports: ['websocket', 'polling'],
    });
    manager1.connect();
    manager1.on(
      'APPOINTMENT-cleared-bills',
      (data: { event: AcceptedRequestTypes; data: any }) => {
        this.playSound('add');
      }
    );
  }

  generateManager(nameSpace: string) {
    // const manager = io(`${environment.socket_url}/${nameSpace}`);
    // manager.emit("balocodes", "I have connected " + dayjs().toISOString())
    // return manager;
  }

  playSound(val: 'add' | 'clear') {
    if (val === 'add') {
      this.newBillSound.load();
      this.newBillSound.play();
    }
    if (val === 'clear') {
      this.clearedBillSound.load();
      this.clearedBillSound.play();
    }
  }

  addItemToTable(item: IAppointment, list: Array<IAppointment>) {
    list.unshift(item);
  }
}
