/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateBillItemDto { 
    status: CreateBillItemDto.StatusEnum;
    txType?: CreateBillItemDto.TxTypeEnum;
    paymentMethod?: CreateBillItemDto.PaymentMethodEnum;
    online_reference?: string;
    invoiceNumber?: number;
    amount: number;
    quantity: number;
    itemId?: string;
    itemName: string;
    itemCode?: string;
    customerId?: string;
    customerName?: string;
    customerUsername?: string;
    customerEmail?: string;
    customerPhone?: string;
    customerSex?: string;
    customerEmailVerified?: boolean;
    departmentRoute?: string;
    hospitalNumber: string;
    admissionNumber?: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoAmount?: number;
    hmoApprovalCode?: string;
    hmoStatus?: CreateBillItemDto.HmoStatusEnum;
    hmoCode?: string;
    customerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    customerPackage?: string;
    medicalHistoryId?: string;
    /**
     * Important meta information
     */
    meta?: object;
    summary?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    raisedById?: string;
    raisedByName?: string;
    raisedByUsername?: string;
    requestId?: string;
    requestCategory?: string;
    slug?: string;
    organizationId?: string;
}
export namespace CreateBillItemDto {
    export type StatusEnum = 'PENDING' | 'PAID' | 'CANCELLED' | 'PAYING_ONLINE' | 'PAID_ONLINE' | 'FAILED_ONLINE_PAYMENT';
    export const StatusEnum = {
        Pending: 'PENDING' as StatusEnum,
        Paid: 'PAID' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        PayingOnline: 'PAYING_ONLINE' as StatusEnum,
        PaidOnline: 'PAID_ONLINE' as StatusEnum,
        FailedOnlinePayment: 'FAILED_ONLINE_PAYMENT' as StatusEnum
    };
    export type TxTypeEnum = 'INCOME' | 'EXPENSE' | 'DISCOUNT' | 'REVERSAL';
    export const TxTypeEnum = {
        Income: 'INCOME' as TxTypeEnum,
        Expense: 'EXPENSE' as TxTypeEnum,
        Discount: 'DISCOUNT' as TxTypeEnum,
        Reversal: 'REVERSAL' as TxTypeEnum
    };
    export type PaymentMethodEnum = 'DEFAULT' | 'CASH' | 'POS' | 'CHEQUE' | 'TRANSFER' | 'ONLINE' | 'PAY_LATER' | 'EXEMPTED' | 'HMO' | 'WALLET';
    export const PaymentMethodEnum = {
        Default: 'DEFAULT' as PaymentMethodEnum,
        Cash: 'CASH' as PaymentMethodEnum,
        Pos: 'POS' as PaymentMethodEnum,
        Cheque: 'CHEQUE' as PaymentMethodEnum,
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        Online: 'ONLINE' as PaymentMethodEnum,
        PayLater: 'PAY_LATER' as PaymentMethodEnum,
        Exempted: 'EXEMPTED' as PaymentMethodEnum,
        Hmo: 'HMO' as PaymentMethodEnum,
        Wallet: 'WALLET' as PaymentMethodEnum
    };
    export type HmoStatusEnum = 'APPROVED' | 'CANCELLED' | 'DEFAULT' | 'IN_PROGRESS';
    export const HmoStatusEnum = {
        Approved: 'APPROVED' as HmoStatusEnum,
        Cancelled: 'CANCELLED' as HmoStatusEnum,
        Default: 'DEFAULT' as HmoStatusEnum,
        InProgress: 'IN_PROGRESS' as HmoStatusEnum
    };
}


