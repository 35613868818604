/**
 * pharmacy-service-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8.2
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IDetail { 
    _id?: string;
    drug_code?: string;
    drug_name?: string;
    route?: string;
    quantity?: number;
    unit?: string;
    dose?: number;
    frequency?: string;
    duration?: string;
    source: IDetail.SourceEnum;
}
export namespace IDetail {
    export type SourceEnum = 'guideline' | 'adjuvant';
    export const SourceEnum = {
        Guideline: 'guideline' as SourceEnum,
        Adjuvant: 'adjuvant' as SourceEnum
    };
}


