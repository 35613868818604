/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IManualSurveysComplaintDifferentials } from './iManualSurveysComplaintDifferentials';
import { IManualSurveysDifferentials } from './iManualSurveysDifferentials';


/**
 * Make all properties in T optional
 */
export interface PartialIManualSurveys { 
    _id?: string | null;
    key?: string;
    title?: string;
    isManual?: PartialIManualSurveys.IsManualEnum;
    surveyType?: PartialIManualSurveys.SurveyTypeEnum;
    path?: string;
    /**
     * differentials field is now deprecated as at April 8, 2022. Please use complaintDifferentials field instead
     */
    differentials?: Array<IManualSurveysDifferentials>;
    complaintDifferentials?: Array<IManualSurveysComplaintDifferentials>;
    dateCreated?: string;
    dateUpdated?: string;
}
export namespace PartialIManualSurveys {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
    export type SurveyTypeEnum = 'examination' | 'complaint';
    export const SurveyTypeEnum = {
        Examination: 'examination' as SurveyTypeEnum,
        Complaint: 'complaint' as SurveyTypeEnum
    };
}


