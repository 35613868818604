/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IAppointmentLocationCoordinates } from './iAppointmentLocationCoordinates';
import { IAppointmentQaInner } from './iAppointmentQaInner';
import { IAppointmentPastConsultantsInner } from './iAppointmentPastConsultantsInner';
import { INextOfKinDetailsId } from './iNextOfKinDetailsId';


/**
 * Make all properties in T optional
 */
export interface PartialIFetchedAppointment { 
    error?: string;
    message?: any | null;
    _id?: string;
    clinic?: string;
    departmentName?: string;
    departmentRoute?: string;
    departmentId?: string;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    date_of_appointment?: string;
    type_of_consultation?: PartialIFetchedAppointment.TypeOfConsultationEnum;
    status?: PartialIFetchedAppointment.StatusEnum;
    paymentStatus?: boolean;
    patient?: INextOfKinDetailsId;
    admission_id?: string;
    patientHmoCode?: string;
    hmoCode?: string;
    email?: string;
    phone?: string;
    packageName?: string;
    hospital_number?: string;
    uid?: string;
    sex?: PartialIFetchedAppointment.SexEnum;
    comment?: string;
    patientFirstName?: string;
    patientLastName?: string;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdById?: string;
    created_at?: string;
    updated_at?: string;
    pastConsultants?: Array<IAppointmentPastConsultantsInner>;
    doctorsInstruction?: string;
    lastAppointmentId?: INextOfKinDetailsId;
    slug?: string;
    hospital_branch?: string;
    age?: number;
    subscriptionId?: INextOfKinDetailsId;
    /**
     * serviceId is the id of the service that the appointment is for. It is required though set to optional here for backward compatibility
     */
    serviceId?: string;
    /**
     * serviceKey is the key of the service that the appointment is for. It is required though set to optional here for backward compatibility
     */
    serviceKey?: string;
    /**
     * Please supply this cost always though it is set to optional here for backward compatibility
     */
    cost?: number;
    completed_at?: string;
    completed_by_name?: string;
    completed_by_id?: string;
    cancelled_at?: string;
    cancelled_by_name?: string;
    cancelled_by_id?: string;
    reasons_for_appointment?: Array<string>;
    qa?: Array<IAppointmentQaInner>;
    completedComplaints?: Array<string>;
    bookingType?: PartialIFetchedAppointment.BookingTypeEnum;
    isEmergency?: boolean;
    needsAmbulance?: boolean;
    locationCoordinates?: IAppointmentLocationCoordinates;
    address?: string;
    checkInStatus?: PartialIFetchedAppointment.CheckInStatusEnum;
    checkInTime?: string;
    checkOutTime?: string;
    callStatus?: PartialIFetchedAppointment.CallStatusEnum;
    callUrl?: string;
    callDuration?: number;
    callStartTime?: string;
    callEndTime?: string;
    callRecordingUrl?: string;
    hasJoinedQueue?: boolean;
    joinedQueueAt?: string;
    joinedQueueType?: PartialIFetchedAppointment.JoinedQueueTypeEnum;
    joinedQueueMessage?: string;
    consultationRoom?: string;
    caregiver_name?: string;
    caregiver_identity?: string;
    caregiver_phone?: string;
    caregiver_email?: string;
}
export namespace PartialIFetchedAppointment {
    export type TypeOfConsultationEnum = 'first-time' | 'follow-up' | 'referral' | 'ward-round';
    export const TypeOfConsultationEnum = {
        FirstTime: 'first-time' as TypeOfConsultationEnum,
        FollowUp: 'follow-up' as TypeOfConsultationEnum,
        Referral: 'referral' as TypeOfConsultationEnum,
        WardRound: 'ward-round' as TypeOfConsultationEnum
    };
    export type StatusEnum = 'pending' | 'vitals-checked' | 'in-progress' | 'done' | 'cancelled' | 'vitals-in-progress' | 'consulting' | 'see-doctor-vitals' | 'see-doctor-lab' | 'see-doctor-rad';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        VitalsChecked: 'vitals-checked' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Done: 'done' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        VitalsInProgress: 'vitals-in-progress' as StatusEnum,
        Consulting: 'consulting' as StatusEnum,
        SeeDoctorVitals: 'see-doctor-vitals' as StatusEnum,
        SeeDoctorLab: 'see-doctor-lab' as StatusEnum,
        SeeDoctorRad: 'see-doctor-rad' as StatusEnum
    };
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type BookingTypeEnum = 'self' | 'other';
    export const BookingTypeEnum = {
        Self: 'self' as BookingTypeEnum,
        Other: 'other' as BookingTypeEnum
    };
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
    export type CallStatusEnum = 'active' | 'inactive' | 'completed';
    export const CallStatusEnum = {
        Active: 'active' as CallStatusEnum,
        Inactive: 'inactive' as CallStatusEnum,
        Completed: 'completed' as CallStatusEnum
    };
    export type JoinedQueueTypeEnum = 'vitals' | 'registration' | 'booking' | 'consultation' | 'lab' | 'radiology' | 'pharmacy';
    export const JoinedQueueTypeEnum = {
        Vitals: 'vitals' as JoinedQueueTypeEnum,
        Registration: 'registration' as JoinedQueueTypeEnum,
        Booking: 'booking' as JoinedQueueTypeEnum,
        Consultation: 'consultation' as JoinedQueueTypeEnum,
        Lab: 'lab' as JoinedQueueTypeEnum,
        Radiology: 'radiology' as JoinedQueueTypeEnum,
        Pharmacy: 'pharmacy' as JoinedQueueTypeEnum
    };
}


