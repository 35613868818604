import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SideBarManager {
    private _state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isOpen(): BehaviorSubject<boolean> {
        return this._state;
    }

    setIsOpen(state: boolean) {
        this._state.next(state);
    }
}