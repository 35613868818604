<main>
    <mat-sidenav-container [hasBackdrop]="false" class="example-container">
        <mat-sidenav #sidenav mode="push" class="example-sidenav"
                     [fixedInViewport]="true" (openedStart)="isOpened()" (closedStart)="isClosed()" [fixedTopGap]="0"
                     [fixedBottomGap]="0">
                     <app-side-bar></app-side-bar>
        </mat-sidenav>

        <mat-sidenav-content>
            <mat-toolbar color="primary" class="example-header" [ngStyle]="navStyle">
                <app-nav (toggleSideBar)="sidenav.toggle()"></app-nav>
            </mat-toolbar>
            <router-outlet></router-outlet>

            <mat-toolbar class="example-footer">&copy;{{currentYear}}  DR. Save A Life</mat-toolbar>

        </mat-sidenav-content>
      </mat-sidenav-container>

</main>

<ng-template #presenceChecker>
  <mat-dialog-content>
    <div class="text-center">
      <h1>{{ currentCounter | number }}</h1>
      <h2>Are you still here?</h2>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="isHere = true;">YES</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>

<ng-template #leftSystem>
  <mat-dialog-content>
    <div class="text-center">
      <h2>Where have you been?</h2>
      <h4>You have been away since {{ awayTime | date : 'short' }}?</h4>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="matDialogRef?.close()">CLOSE</button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>
