/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WalletTransactionDto { 
    id: number;
    amount: number;
    createdById: string;
    createdByName: string;
    walletType: WalletTransactionDto.WalletTypeEnum;
    paymentMethod?: WalletTransactionDto.PaymentMethodEnum;
    note?: string;
}
export namespace WalletTransactionDto {
    export type WalletTypeEnum = 'REGULAR' | 'ADMISSION';
    export const WalletTypeEnum = {
        Regular: 'REGULAR' as WalletTypeEnum,
        Admission: 'ADMISSION' as WalletTypeEnum
    };
    export type PaymentMethodEnum = 'DEFAULT' | 'CASH' | 'POS' | 'CHEQUE' | 'TRANSFER' | 'ONLINE' | 'PAY_LATER' | 'EXEMPTED' | 'HMO' | 'WALLET';
    export const PaymentMethodEnum = {
        Default: 'DEFAULT' as PaymentMethodEnum,
        Cash: 'CASH' as PaymentMethodEnum,
        Pos: 'POS' as PaymentMethodEnum,
        Cheque: 'CHEQUE' as PaymentMethodEnum,
        Transfer: 'TRANSFER' as PaymentMethodEnum,
        Online: 'ONLINE' as PaymentMethodEnum,
        PayLater: 'PAY_LATER' as PaymentMethodEnum,
        Exempted: 'EXEMPTED' as PaymentMethodEnum,
        Hmo: 'HMO' as PaymentMethodEnum,
        Wallet: 'WALLET' as PaymentMethodEnum
    };
}


