/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { INextOfKinDetailsId } from './iNextOfKinDetailsId';


export interface IAntenatalQuestionAndAnswer { 
    _id?: string;
    antenatalEnrollmentId: INextOfKinDetailsId;
    patientFirstName?: string;
    patientLastName?: string;
    patientId?: INextOfKinDetailsId;
    hospitalNumber: string;
    week: number;
    day: number;
    questionKey: string;
    questionText: string;
    answerKey: string;
    answerText: string;
    slug?: string;
    status?: IAntenatalQuestionAndAnswer.StatusEnum;
    checkedByFirstName?: string;
    checkedByLastName?: string;
    checkedByStaffId?: string;
    checkedByTime?: string;
    created_at?: string;
    updated_at?: string;
}
export namespace IAntenatalQuestionAndAnswer {
    export type StatusEnum = 'pending' | 'completed';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Completed: 'completed' as StatusEnum
    };
}


