/**
 * hmo-service-salh
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.1.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IHMO { 
    _id?: string;
    name: string;
    code: string;
    account_code?: string;
    address?: string;
    location?: string;
    phone: string;
    email: string;
    category?: string;
    status?: IHMO.StatusEnum;
    created_at?: string;
    updated_at?: string;
}
export namespace IHMO {
    export type StatusEnum = 'active' | 'inactive';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum
    };
}


