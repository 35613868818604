/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IGlobalAccelerexData { 
    Type: IGlobalAccelerexData.TypeEnum;
    TransactionReference: string;
    Amount: number;
    StatusCode: string;
    MerchantId: string;
    Currency: string;
}
export namespace IGlobalAccelerexData {
    export type TypeEnum = 'Invoice' | 'Purchase';
    export const TypeEnum = {
        Invoice: 'Invoice' as TypeEnum,
        Purchase: 'Purchase' as TypeEnum
    };
}


