/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialIAntenatalQuestionAndAnswer } from './partialIAntenatalQuestionAndAnswer';


/**
 * Make all properties in T optional
 */
export interface PartialAntenatalQuestionAndAnswerFilter { 
    filter?: PartialIAntenatalQuestionAndAnswer;
    start_date?: string;
    end_date?: string;
    filter_by_created_at?: PartialAntenatalQuestionAndAnswerFilter.FilterByCreatedAtEnum;
    page?: number;
    limit?: number;
    order?: string;
    select?: string;
    populate?: boolean;
}
export namespace PartialAntenatalQuestionAndAnswerFilter {
    export type FilterByCreatedAtEnum = 'yes' | 'no';
    export const FilterByCreatedAtEnum = {
        Yes: 'yes' as FilterByCreatedAtEnum,
        No: 'no' as FilterByCreatedAtEnum
    };
}


