/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AutoCreateWalletDto { 
    hospital_number: string;
    status: AutoCreateWalletDto.StatusEnum;
}
export namespace AutoCreateWalletDto {
    export type StatusEnum = 'OPEN' | 'BLOCKED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        Blocked: 'BLOCKED' as StatusEnum
    };
}


