import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as dayjs from "dayjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config?: SalAppConfig;
  code?: string;
  originWithoutProtocol = window.location.origin.replace(
    /^https?:\/\/|\/$/g,
    ""
  );

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {}

  validConfig = () => {
    const storedConfig = JSON.parse(
      localStorage.getItem("sal-config$") || "{}"
    );
    const storedConfigExpiry = Number(
      localStorage.getItem("sal-config-expiry$")
    );
    const storedConfigOrigin = localStorage.getItem("sal-config-origin$");

    if (
      storedConfigOrigin === this.originWithoutProtocol &&
      storedConfig &&
      storedConfigExpiry &&
      dayjs().isBefore(storedConfigExpiry)
    ) {
      this.config = storedConfig;
    }

    return this.config;
  };

  static get config(): SalAppConfig {
    return JSON.parse(localStorage.getItem("sal-config$") || "{}");
  }

  async loadConfig(retries = 0, code = ""): Promise<SalAppConfig | void> {
    console.log(this.validConfig())
    if (!this.validConfig() && !code && code !== "RWOOOSH") {
      const _code = await Swal.fire({
        text: "Enter Hospital Code",
        input: "text",
      }).then((value) => {
        return value.value;
      });

      if (_code) {
        return await this.loadConfig(0, _code);
      }
    }

    console.log("NOT LOADING")

    localStorage.setItem("sal-config-origin$", this.originWithoutProtocol);
    if (this.config) {
      return this.config;
    }
    if (retries > 4) {
      return;
    }

    retries += 1;

    if (this.config) return this.config;

    const toReturn = await this.http
      .get<SalAppConfig>(
        `https://sal-config.drsavealife.com/load-frontend-config?${
          code
            ? `code=${code}`
            : `configName=${this.originWithoutProtocol}.json`
        }`
      )
      .toPromise()

      .then((config) => {
        if (!config) {
          this.snackBar.open(
            `Could not load config. Retrying in ${retries * 5 * 1000} seconds`
          );
          setTimeout(
            async () => {
              this.snackBar.dismiss();
              return await this.loadConfig(retries + 1);
            },
            retries * 5 * 1000
          );
        }
        this.config = config;
        localStorage.setItem("sal-config$", JSON.stringify(config));
        localStorage.setItem(
          "sal-config-expiry$",
          new Date(dayjs().add(5, "minutes").toDate()).getTime().toString()
        );
        window.location.reload();
        return;
      })
      .catch(async (e) => {
        this.snackBar.open(
          `Could not load config. Retrying in ${retries * 5 * 1000} seconds`
        );
        setTimeout(
          async () => {
            this.snackBar.dismiss();
            return await this.loadConfig(retries + 1);
          },
          retries * 5 * 1000
        );
      });

    console.log("TORETURN", toReturn);
    return toReturn;
  }
}

export interface SalAppConfig {
  care_url: string;
  hmo_url: string;
  production: boolean;
  url: string;
  records_url: string;
  clinicals_url: string;
  clinicals_engine_url: string;
  medical_history_v2_url: string;
  notification_url: string;
  NOTIFICATION_SOCKET_URL_V2: string;
  medical_url: string;
  pharmacy_url: string;
  new_pharmacy_url: string;
  procedure_url: string;
  staff_url: string;
  service_url: string;
  finance_url: string;
  lab_url: string;
  admission_url: string;
  upload_file_url: string;
  store_url: string;
  user_guide_url: string;
  emailUrl: string;
  messaging_url: string;
  socket_url: string;
  messaging_mode: "TWILIO" | "INVIDEO"; // 'TWILIO' or 'INVIDEO'

  keycloakConfig: {
    clientId: string;
    realm: string;
    url: string;
  };
  initOptions: {
    onLoad: any;
    silentCheckSsoRedirectUri: string;
  };
  ZEGO_APP_ID: number; //356424243,
  tiny_mc: {
    api_key: string;
  };
}
