/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IUpdateCheckIn { 
    checkInTime?: string;
    checkInStatus?: IUpdateCheckIn.CheckInStatusEnum;
    checkOutTime?: string;
}
export namespace IUpdateCheckIn {
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
}


