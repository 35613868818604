/**
 * pharmacy-service-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8.2
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPrescriptionPatientId } from './iPrescriptionPatientId';
import { Dose } from './dose';


export interface IPrescription { 
    _id?: string;
    category_id?: number;
    category_name?: string;
    appointment_id: string;
    prescription?: string;
    paymentStatus?: boolean;
    route?: string;
    dose?: Dose;
    unit?: string;
    frequency?: string;
    duration?: string;
    duration_value?: string;
    duration_unit?: string;
    prescription_doctor_format?: string;
    prescription_patient_format?: string;
    doctors_instructions?: Array<string>;
    prescription_type?: IPrescription.PrescriptionTypeEnum;
    status?: IPrescription.StatusEnum;
    quantity_given?: number;
    hospital_id?: string;
    selected?: boolean;
    item_id?: number;
    item_name?: string;
    dosage?: string;
    as_labelled?: boolean;
    item_cost?: number;
    patient_hmo_code?: string;
    hmo_code?: string;
    hmo_id?: string;
    hmo_name?: string;
    patient_sex?: string;
    patient_id?: IPrescriptionPatientId;
    patient_name?: string;
    patient_hospital_number?: string;
    storeStateId?: number;
}
export namespace IPrescription {
    export type PrescriptionTypeEnum = 'adjuvant' | 'regular';
    export const PrescriptionTypeEnum = {
        Adjuvant: 'adjuvant' as PrescriptionTypeEnum,
        Regular: 'regular' as PrescriptionTypeEnum
    };
    export type StatusEnum = 'not-attended' | 'bill-generated' | 'paid' | 'dispensed' | 'cancelled';
    export const StatusEnum = {
        NotAttended: 'not-attended' as StatusEnum,
        BillGenerated: 'bill-generated' as StatusEnum,
        Paid: 'paid' as StatusEnum,
        Dispensed: 'dispensed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


