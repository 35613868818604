/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.4.1
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IResultsSummaryResults } from './iResultsSummaryResults';


export interface IProcedure { 
    _id?: string | null;
    id?: number;
    serviceName?: string;
    serviceKey?: string;
    serviceId?: string;
    results?: Array<IResultsSummaryResults>;
    notes?: string;
    hospitalNumber: string;
    firstName: string;
    lastName: string;
    otherName?: string;
    sex: IProcedure.SexEnum;
    phone?: string;
    email?: string;
    cost?: number;
    vitalsId?: string;
    appointmentId?: string;
    admissionId?: string;
    medicalHistoryId: string;
    finalDiagnosisKey: string;
    status?: IProcedure.StatusEnum;
    raisedByUserName?: string;
    raisedById?: string | null;
    raisedByFullName?: string;
    completedByUserName?: string;
    completedByById?: string | null;
    completedByFullName?: string;
    date: string;
}
export namespace IProcedure {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'pending' | 'in-progress' | 'completed';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Completed: 'completed' as StatusEnum
    };
}


