/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreatePackageData { 
    id?: number;
    package_name: string;
    package_is_ranked: CreatePackageData.PackageIsRankedEnum;
    package_key: string;
    services: Array<number>;
}
export namespace CreatePackageData {
    export type PackageIsRankedEnum = 'ranked' | 'unranked';
    export const PackageIsRankedEnum = {
        Ranked: 'ranked' as PackageIsRankedEnum,
        Unranked: 'unranked' as PackageIsRankedEnum
    };
}


