/**
 * pharmacy-service-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8.2
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPrescription } from './iPrescription';
import { IPrescriptionPatientId } from './iPrescriptionPatientId';
import { IPharmacyRequestDiagnosesInner } from './iPharmacyRequestDiagnosesInner';
import { IPharmacyRequestPaymentInfoInner } from './iPharmacyRequestPaymentInfoInner';


export interface IPharmacyRequest { 
    _id?: string;
    appointment_id: string;
    patient_hmo_code?: string;
    hmo_code?: string;
    hmo_name?: string;
    hmo_id?: string;
    patient_sex?: string;
    patient_id?: IPrescriptionPatientId;
    patient_name?: string;
    patient_hospital_number?: string;
    patient_symptoms?: Array<string>;
    patient_diagnosis?: string;
    diagnoses?: Array<IPharmacyRequestDiagnosesInner>;
    departmentRoute?: string;
    raised_by_id?: IPrescriptionPatientId;
    raised_by_name?: string;
    raised_from_id?: IPrescriptionPatientId;
    raised_from_name?: string;
    serial_no?: string;
    status?: IPharmacyRequest.StatusEnum;
    prescriptions?: Array<IPrescription>;
    paymentStatus?: boolean;
    hospital_id?: string;
    billGenerated?: boolean;
    dispensedById?: string;
    dispensedByName?: string;
    paymentInfo?: Array<IPharmacyRequestPaymentInfoInner>;
    idsOfRxWithBills?: Array<string>;
    dispensedAt?: string;
    created_at?: string;
    updated_at?: string;
}
export namespace IPharmacyRequest {
    export type StatusEnum = 'not-attended' | 'bill-generated' | 'paid' | 'dispensed' | 'cancelled';
    export const StatusEnum = {
        NotAttended: 'not-attended' as StatusEnum,
        BillGenerated: 'bill-generated' as StatusEnum,
        Paid: 'paid' as StatusEnum,
        Dispensed: 'dispensed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


