/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HospitalServices } from './hospitalServices';


/**
 * Make all properties in T optional
 */
export interface PartialPackageEntity { 
    id?: number;
    package_name?: string;
    package_is_ranked?: PartialPackageEntity.PackageIsRankedEnum;
    slug?: string;
    package_key?: string;
    services?: Array<HospitalServices>;
}
export namespace PartialPackageEntity {
    export type PackageIsRankedEnum = 'ranked' | 'unranked';
    export const PackageIsRankedEnum = {
        Ranked: 'ranked' as PackageIsRankedEnum,
        Unranked: 'unranked' as PackageIsRankedEnum
    };
}


