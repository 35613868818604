import { KeycloakService, KeycloakOptions } from "keycloak-angular";
import { ConfigService, SalAppConfig } from "./config.service";
import Swal from "sweetalert2";

export function initializer(
  keycloak: KeycloakService,
  configService: ConfigService
): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return configService.loadConfig(0).then(() => {
      const options: KeycloakOptions = {
        config: configService.config?.keycloakConfig,
        initOptions: configService.config?.initOptions,
        enableBearerInterceptor: true,
      };
      return keycloak.init(options);
    });
  };

  // return (): Promise<any> => {
  //   return keycloak.init(options);
  // };
}

export function environment(key: keyof SalAppConfig) {
  return (configService: ConfigService) => {
    // while(!configService.config?.[key]) {
    //   continue
    // }
    console.log("RETURNING ENV", key, configService.config?.[key]);
    return () => configService.config?.[key];
  };
}
