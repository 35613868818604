/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 4.2.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { INextOfKinDetailsId } from './iNextOfKinDetailsId';


/**
 * Make all properties in T optional
 */
export interface PartialIImmunizationAppointment { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    sex?: PartialIImmunizationAppointment.SexEnum;
    hospitalNumber?: string;
    email?: string;
    phone?: string;
    appointmentDate?: string;
    patientId?: INextOfKinDetailsId;
    patientDateOfBirth?: string;
    patientAgeInWeeks?: number;
    status?: PartialIImmunizationAppointment.StatusEnum;
    bookedByStaffId?: string;
    bookedByStaffFirstName?: string;
    bookedByStaffLastName?: string;
    patientHmoCode?: string;
    hmoCode?: string;
    hmoId?: string;
    hmoName?: string;
    created_at?: string;
    updated_at?: string;
}
export namespace PartialIImmunizationAppointment {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'pending' | 'completed' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


