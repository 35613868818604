/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.4.1
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IQuestionsSummaryDetails } from './iQuestionsSummaryDetails';


export interface IQuestionsSummary { 
    _id?: string | null;
    surveyKey: string;
    surveyId?: string;
    surveyTitle?: string;
    isManual?: IQuestionsSummary.IsManualEnum;
    details: Array<IQuestionsSummaryDetails>;
    date_created?: string;
    notes?: string;
}
export namespace IQuestionsSummary {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
}


