/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Make all properties in T optional
 */
export interface PartialIAdmission { 
    _id?: string | null;
    hospitalNumber?: string;
    firstName?: string;
    lastName?: string;
    otherName?: string;
    sex?: PartialIAdmission.SexEnum;
    phone?: string;
    email?: string;
    vitalsIds?: string | Array<any> | null;
    initialAppointmentId?: string | null;
    medicalHistoriesIds?: Array<string>;
    isDischarged?: PartialIAdmission.IsDischargedEnum;
    admissionDate?: string;
    dischargeDate?: string;
    admissionNote?: string;
    dischargeNote?: string;
    admittedByUserName?: string;
    admittedById?: string | null;
    admittedByFullName?: string;
    dischargedByUserName?: string;
    dischargedById?: string;
    dischargedByFullName?: string;
    generalNotes?: string;
    slug?: string;
    dateCreated?: string;
    dateUpdated?: string;
}
export namespace PartialIAdmission {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type IsDischargedEnum = false | true;
    export const IsDischargedEnum = {
        False: false as IsDischargedEnum,
        True: true as IsDischargedEnum
    };
}


