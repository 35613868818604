/**
 * clinicals-backend-home-clinic
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface INewResult { 
    error: INewResult.ErrorEnum;
    message?: string;
    details?: any | null;
}
export namespace INewResult {
    export type ErrorEnum = false | true;
    export const ErrorEnum = {
        False: false as ErrorEnum,
        True: true as ErrorEnum
    };
}


