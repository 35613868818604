/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.4.1
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IDiagnosesSummaryQuestions } from './iDiagnosesSummaryQuestions';
import { IDiagnosesSummaryPrescriptions } from './iDiagnosesSummaryPrescriptions';


export interface IDiagnosesSummary { 
    key: string;
    title: string;
    differential_id?: string;
    percentage?: number;
    isManual?: IDiagnosesSummary.IsManualEnum;
    _id?: string | null;
    questions: Array<IDiagnosesSummaryQuestions>;
    prescriptions?: Array<IDiagnosesSummaryPrescriptions>;
    notes?: string;
}
export namespace IDiagnosesSummary {
    export type IsManualEnum = false | true;
    export const IsManualEnum = {
        False: false as IsManualEnum,
        True: true as IsManualEnum
    };
}


