/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WalletEntity } from './walletEntity';
import { PayerDto } from './payerDto';
import { UpdateBillItemDto } from './updateBillItemDto';


export interface PaymentFromBillDto { 
    bills: Array<UpdateBillItemDto>;
    payer: PayerDto;
    wallet?: WalletEntity;
    walletTransactionBeneficiary?: PaymentFromBillDto.WalletTransactionBeneficiaryEnum;
}
export namespace PaymentFromBillDto {
    export type WalletTransactionBeneficiaryEnum = 'SELF' | 'OTHER';
    export const WalletTransactionBeneficiaryEnum = {
        Self: 'SELF' as WalletTransactionBeneficiaryEnum,
        Other: 'OTHER' as WalletTransactionBeneficiaryEnum
    };
}


