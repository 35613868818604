import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Output() toggleSideBar: EventEmitter<boolean> = new EventEmitter();
  user: any;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getLoggedUser();
    // console.log(this.user);
    
  }

  toggle() {
    this.toggleSideBar.emit(true);
  }

  logout() {
    this.authService.logout();
  }

}
