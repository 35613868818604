import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnderScoreToSpacePipe } from './under-score-to-space.pipe';
import { DashToSpacePipe } from './dash-to-space.pipe';
import { RowNumberPipe } from './row-number.pipe';
import { RevertDatePipe } from './revert-date.pipe';
import {RelativeDatePipe} from "./relative-date.pipe";
import { BookedByWhoPipe } from './booked-by-who.pipe';
import { TimeOverduePipe } from './time-overdue.pipe';



@NgModule({
  declarations: [
    UnderScoreToSpacePipe,
    DashToSpacePipe,
    RowNumberPipe,
    RevertDatePipe,
    RelativeDatePipe,
    BookedByWhoPipe,
    TimeOverduePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    UnderScoreToSpacePipe,
    DashToSpacePipe,
    RowNumberPipe,
    RevertDatePipe,
    RelativeDatePipe,
    TimeOverduePipe
  ]
})
export class PipesModule { }
